/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import api from '../utils/api';
import { Button, Card, Flex, Input, Progress, Select, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";

import {
  Box,
  Text,
  VStack,
  Heading,
} from "@chakra-ui/react";
import classes from '../components/summary/Summary.module.scss';
import useDataFetch from '../hook/useDataFetch';
import { IMemberIncentive, IPortalProfileTable, IPortalTable, IProposalTable, ITarget, IusersTable, MonthRevenueTable } from '../interfaces/Itable';
import LoadingSpinner from '../components/UI/loadingSpinner/LoadingSpinner';
import { UpsellStat, UserStat } from '../interfaces/IsummData';
import UserDetailBox from './UserDetailBox';
import CustomTable from '../components/tables/customTable/CustomTable';
import { allocateprofile, allocatetarget, monthlyRevenue, userhiredPorposal } from '../constants/tables';

interface PortalDictionary {
  [key: string]: string;
}

interface SelectedData {
  datePicker?: {
    totalConnects: number;
    totalMoneyUsedOnConnects: number;
    totalProposals: number;
    totalReceivedAmount: number;
    totalSales: number;
  };

}

interface MonthData {
  // year: number;
  // monthlyStats: { [key: string]: { totalProposals: number; totalSales: number } | undefined };
  year: number;
  monthlyStats: Record<
    string,
    { totalSales: number; totalProposals: number }
  >;
}
// const { data: montlystats } = useDataFetch<{
//   year: number;
//   monthlyStats: Record<
//     string,
//     { totalSales: number; totalProposals: number }
//   >;
// }>(MonthlyStat);

const UserDetail: React.FC = () => {
  const { userId } = useParams<{ userId: string }>();
  const PortalPorfileUrl = `${process.env.REACT_APP_API_URL}/api/portal/profile`
  const { data: profile } = useDataFetch<IPortalProfileTable[]>(PortalPorfileUrl);
  const [loadings, setLoading] = useState(true)
  const [portalIdToName, setPortalIdToName] = useState<PortalDictionary>({});
  const [selecteduser, setSelectedUser] = useState<any>(null);
  const [loading1, setLoading1] = useState(true);
  const navigate = useNavigate();
  const [count, setCount] = useState<number>(0);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedPortalId, setSelectedPortalId] = useState<string | number | null>(null);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const portalProposalUrl = `${process.env.REACT_APP_API_URL}/api/portal/proposals?id=${userId}`;
  const MemberEarningUrl = `${process.env.REACT_APP_API_URL}/api/portal/user_earning_record?id=${userId}`;
  const UserStatsURl = `${process.env.REACT_APP_API_URL}/api/portal/user_all_stats?id=${userId}${selectedPortalId ? `&portalId=${selectedPortalId}` : ''}`
  const PortalUrl = `${process.env.REACT_APP_API_URL}/api/portal`
  const UpsellStat = `${process.env.REACT_APP_API_URL}/api/portal/user_upsell_stats?id=${userId}${selectedPortalId ? `&portalId=${selectedPortalId}` : ''}`
  const MonthlyStat = `${process.env.REACT_APP_API_URL}/api/portal/monthly-stats?id=${userId}${selectedYear ? `&year=${selectedYear}` : ""}`
  const { data: upselldata } = useDataFetch<UpsellStat>(UpsellStat);
  const { monthly: monthlyUpsell, allTime: allUpsell, currentYear: yearUpsell, weekly: weekUpsell, daily: dailyUpsell } = upselldata || {};
  const { data: userstatdata, } = useDataFetch<UserStat>(UserStatsURl);
  const { monthly, allTime, currentYear, weekly, daily } = userstatdata || {};
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState<IProposalTable[]>([]);
  const { data: MemberIncentive, refetch } = useDataFetch<IMemberIncentive>(MemberEarningUrl);
  const { data, error, loading } = useDataFetch<IProposalTable[]>(portalProposalUrl);
  const { data: portaldata } = useDataFetch<IPortalTable[]>(PortalUrl);
  const { data: monthydata } = useDataFetch<MonthData>(MonthlyStat);
  const hiredProposal = data?.filter((el) => el.status === 'hired') ?? [];
  const [selectedData, setSelectedData] = useState<SelectedData>({});
  const [selectedUpsellData, setSelectedUpsellData] = useState<SelectedData>({});
  const [selected, setSelected] = useState<string>("");


  const monthdata: MonthRevenueTable[] = monthydata?.monthlyStats
    ? Object.entries(monthydata.monthlyStats).map(([month, data], index) => {
      // Find the target for the current month
      const currentTarget = selecteduser?.target?.find((tar: any) => {
        const targetMonth = new Date(tar.targetDate).toLocaleString(
          "default",
          {
            month: "short",
          }
        );

        return targetMonth === month;
      });

      // If no target found for the current month, find the latest previous target
      let targetAmt = 0;
      if (currentTarget) {
        targetAmt = currentTarget.targetAmt;
      } else {
        const sortedTargets = [...(selecteduser?.target || [])]
          .sort(
            (a: ITarget, b: ITarget) =>
              new Date(a.targetDate).getTime() -
              new Date(b.targetDate).getTime()
          )
          .filter(
            (tar: ITarget) =>
              new Date(tar.targetDate) <=
              new Date(`${month} 1, ${new Date().getFullYear()}`)
          );

        if (sortedTargets.length > 0) {
          targetAmt = sortedTargets[sortedTargets.length - 1].targetAmt;
        }
      }

      return {
        id: index + 1,
        month,
        totalProposals: data.totalProposals || 0,
        totalSales: data.totalSales || 0,
        // targets: target ? target.targetAmt : 0,
        targets: targetAmt,
      };
    })
    : [];



  // const monthdata: MonthRevenueTable[] = monthydata
  //   ? Object.entries(monthydata.monthlyStats).map(([month, stats], index) => ({
  //     id: index + 1,
  //     month,
  //     totalProposals: stats?.totalProposals || 0,
  //     totalSales: stats?.totalSales || 0,
  //   }))
  //   : [];


  const startingYear = 2024;
  const currentYearValue = new Date().getFullYear();

  // Generate the year options
  const years = Array.from(
    { length: currentYearValue - startingYear + 1 },
    (_, index) => startingYear + index
  );

  const fetchFilteredData = async (portalId: string | number | null, startDate: string, endDate: string) => {
    const response = await api.get(`/portal/user_all_stats?id=${userId}&startDate=${startDate}&endDate=${endDate}${portalId ? `&portalId=${portalId}` : ''}`);
    setSelectedData(response.data);
    const upsellresponse = await api.get(`/portal/user_upsell_stats?id=${userId}&startDate=${startDate}&endDate=${endDate}${selectedPortalId ? `&portalId=${selectedPortalId}` : ''}`);
    setSelectedUpsellData(upsellresponse.data)
  };


  const handleSearch = () => {
    setCount(5);
    if (startDate && endDate || selectedPortalId) {
      fetchFilteredData(selectedPortalId, startDate, endDate);
    }
  };

  useEffect(() => {
    if (startDate && endDate || selectedPortalId) {
      fetchFilteredData(selectedPortalId, startDate, endDate);
    }
  }, [selectedPortalId, startDate, endDate]);


  useEffect(() => {
    if (profile) {

      const portalNames = (profile || []).reduce((acc: PortalDictionary, curr) => {
        acc[curr?.portal?._id] = curr?.portal?.portalName;
        return acc;
      }, {});
      setPortalIdToName(portalNames);
      setLoading(false);
    }

  }, [profile]);

  useEffect(() => {
    if (selectedPortalId === null) {
      const sale = userstatdata?.monthly?.totalSales ?? 0
      localStorage.setItem('acheivemonthincentive', sale.toString())
    }
  }, [userstatdata])

  const filterByDate = (proposals: IProposalTable[], period: number, startDate?: string, endDate?: string): IProposalTable[] => {

    const now = new Date();
    return proposals.filter((proposal) => {
      const proposalDate = new Date(proposal?.createdAt);

      if (startDate && endDate && period === 5) {
        const start = new Date(startDate);
        const end = new Date(endDate);
        end.setHours(23, 59, 59, 999);
        return proposalDate >= start && proposalDate <= end;
      }

      switch (period) {
        case 4: // Daily
          return proposalDate.toDateString() === now.toDateString();

        case 3: // Weekly

          const startOfWeek = new Date(now);
          startOfWeek.setDate(now.getDate() - now.getDay() + 1);

          startOfWeek.setHours(0, 0, 0, 0);


          const endOfWeek = new Date(startOfWeek);
          endOfWeek.setDate(startOfWeek.getDate() + 6);
          endOfWeek.setHours(23, 59, 59, 999);

          return proposalDate >= startOfWeek && proposalDate <= endOfWeek;
        case 0:


          const startOfCurrentMonth = new Date(now.getFullYear(), now.getMonth(), 1);

          const endOfCurrentMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);
          endOfCurrentMonth.setHours(23, 59, 59, 999);

          return proposalDate >= startOfCurrentMonth && proposalDate <= endOfCurrentMonth;


        case 1:

          const startOfCurrentYear = new Date(now.getFullYear(), 0, 1);

          const endOfCurrentYear = new Date(now.getFullYear(), 11, 31);
          endOfCurrentYear.setHours(23, 59, 59, 999);

          return proposalDate >= startOfCurrentYear && proposalDate <= endOfCurrentYear;


        case 2: // Total
        default:
          return true;
      }
    });

  };


  useEffect(() => {

    if (hiredProposal) {
      let filtered = filterByDate(hiredProposal, count, startDate, endDate).filter(
        (item) =>
          item.profile?.profileName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.proposalLink.toLowerCase().includes(searchTerm.toLowerCase())
      );

      setFilteredData(filtered)
    }

  }, [data, searchTerm, count, startDate, endDate])



  const fetchUser = async () => {
    try {
      const response = await api.get(`/users/${userId}`);
      const userData = response.data;

      // Calculate profile counts
      const profileNamesToCount = userData.profilesAllocated.map((el: any) => el.profileName);
      const profileCount = profileNamesToCount.reduce((acc: any, name: any) => {
        acc[name] = { hired: 0, total: 0 };
        return acc;
      }, {});
      // const filteredTotalData = filterByDate(data ?? [], count);
      const filteredTotalData = filterByDate(data ?? [], count, startDate, endDate);



      filteredData.forEach(item => {
        const profileName = item.profile?.profileName;
        if (profileNamesToCount.includes(profileName)) {
          profileCount[profileName].hired++;

        }
      });

      filteredTotalData.forEach(item => {
        const profileName = item.profile?.profileName;

        if (profileNamesToCount.includes(profileName)) {
          profileCount[profileName].total++;
        }
      });

      // Include profile counts in profilesAllocated

      const updatedProfilesAllocated = userData.profilesAllocated.map((profile: any) => ({
        ...profile,
        hiredProposalCount: profileCount[profile.profileName]?.hired || 0,
        totalProposalCount: profileCount[profile.profileName]?.total || 0,
      }));


      // Target-related logic -----------------------------------------------------
      const targets = userData.target || [];

      const monthToLatestTarget = targets.reduce((acc: any, target: any) => {
        const targetMonth = new Date(target.targetDate).getMonth();
        const targetYear = new Date(target.targetDate).getFullYear();

        const key = `${targetYear}-${targetMonth}`; // Unique key for each month-year
        if (
          !acc[key] ||
          new Date(acc[key].targetDate) < new Date(target.targetDate)
        ) {
          acc[key] = target; // Replace previous target with latst target of month
        }
        return acc;
      }, {});

      const validTargets = Object.values(monthToLatestTarget);

      setSelectedUser({
        ...userData,
        target: validTargets,
        profilesAllocated: updatedProfilesAllocated,
        portalIdToName: portalIdToName,
      });

      // setSelectedUser({
      //   ...userData,
      //   profilesAllocated: updatedProfilesAllocated,
      //   portalIdToName: portalIdToName,
      // });

      setLoading1(false);
    } catch (error) {

      console.log('Error fetching user:', error);
      setLoading1(false);
    }
  };



  useEffect(() => {
    fetchUser();
  }, [selected, userId, filteredData, count, data, startDate, endDate]);


  if (loading1) {
    return <div>Loading...</div>;
  }

  if (!selecteduser) {
    return <div>User not found</div>;
  }


  const renderSalesData = () => {
    switch (count) {
      case 4:
        return <><Text>Revenue: ${dailyUpsell?.totalSales}</Text> <Text>Received Amount: ${dailyUpsell?.totalReceivedAmount}</Text> </>;
      case 3:
        return <> <Text>Revenue: ${weekUpsell?.totalSales}</Text> <Text>Received Amount: ${weekUpsell?.totalReceivedAmount}</Text> </>;
      case 0:
        return <> <Text>Revenue: ${monthlyUpsell?.totalSales}</Text> <Text>Received Amount: ${monthlyUpsell?.totalReceivedAmount}</Text> </>;
      case 1:
        return <> <Text>Revenue: ${yearUpsell?.totalSales}</Text> <Text>Received Amount: ${yearUpsell?.totalReceivedAmount}</Text> </>;
      case 2:
        return <> <Text>Revenue: ${allUpsell?.totalSales}</Text> <Text>Received Amount: ${allUpsell?.totalReceivedAmount}</Text> </>;
      case 5:
        return <> <Text>Revenue: ${selectedUpsellData?.datePicker?.totalSales}</Text> <Text>Received Amount: ${selectedUpsellData?.datePicker?.totalReceivedAmount}</Text> </>;
      default:
        return null;
    }
  };


  const Connects = {
    icon: "arcticons:connect",
    text:
      count === 0
        ? "thisMonthConnects"
        : count === 1
          ? "thisYearConnects"
          : count === 3 ? "thisWeekConnects"
            : count === 4 ? "dailyconnect"
              : count === 5 ? "connect"
                : "thisTotalConnects",
    amount:
      count === 0
        ? monthly?.totalConnects || 0
        : count === 1
          ? currentYear?.totalConnects || 0
          : count === 3 ? weekly?.totalConnects || 0
            : count === 4 ? daily?.totalConnects || 0
              : count === 5 ? selectedData?.datePicker?.totalConnects || 0
                : allTime?.totalConnects || 0,
    currency: "",
  };

  const Sales = {
    // icon: "icon-park-outline:transaction-order",
    icon: "carbon:sales-ops",
    text:
      count === 0
        ? "thisMonthRevenue"
        : count === 1
          ? "thisYearRevenue"
          : count === 3 ? "thisWeekRevenue"
            : count === 4 ? "dailyRevenue"
              : count === 5 ? "Revenue"
                : "thisTotalRevenue",
    amount:
      count === 0
        ? (monthly?.totalSales || 0).toFixed(2)
        : count === 1
          ? (currentYear?.totalSales || 0).toFixed(2)
          : count === 3 ? (weekly?.totalSales || 0).toFixed(2)
            : count === 4 ? (daily?.totalSales || 0).toFixed(2)
              : count === 5 ? (selectedData?.datePicker?.totalSales || 0).toFixed(2)
                : (allTime?.totalSales || 0).toFixed(2),
    currency: "",
  };


  const ConnectsCost = {
    // icon: "jam:coin",
    icon: "nimbus:money",
    // icon: "arcticons:connect",
    text:
      count === 0
        ? "thisMonthUsedMoneyOnConnects"
        : count === 1
          ? "thisYearUsedMoneyOnConnects"
          : count === 3 ? "thisWeekUsedMoneyOnConnects"
            : count === 4 ? 'dailyUsedMoneyOnConnects'
              : count === 5 ? 'connectCost'
                : "thisTotalUsedMoneyOnConnects",
    amount:
      count === 0
        ? (monthly?.totalMoneyUsedOnConnects || 0).toFixed(2)
        : count === 1
          ? (currentYear?.totalMoneyUsedOnConnects || 0).toFixed(2)
          : count === 3 ? (weekly?.totalMoneyUsedOnConnects || 0).toFixed(2)
            : count === 4 ? (daily?.totalMoneyUsedOnConnects || 0).toFixed(2)
              : count === 5 ? (selectedData?.datePicker?.totalMoneyUsedOnConnects || 0).toFixed(2)
                : (allTime?.totalMoneyUsedOnConnects || 0).toFixed(2),
    currency: "",
  };

  const Proposals = {
    icon: "icon-park-outline:transaction-order",
    text:
      count === 0
        ? "thisMonthProposals"
        : count === 1
          ? "thisYearProposals"
          : count === 3 ? "thisweeklyProposals"
            : count === 4 ? "dailyproposals"
              : count === 5 ? "proposals"
                : "thisTotalProposals",
    amount:
      count === 0
        ? monthly?.totalProposals || 0
        : count === 1
          ? currentYear?.totalProposals || 0
          : count === 3 ? weekly?.totalProposals || 0
            : count === 4 ? daily?.totalProposals || 0
              : count === 5 ? selectedData?.datePicker?.totalProposals || 0
                : allTime?.totalProposals || 0,
    currency: "",
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return <p>Error loading proposals</p>;
  }



  const handlePortalClick = (portalId: string | number) => {
    setSelectedPortalId(portalId);

  };



  const capitalizeFirstLetter = (text: string) => {
    if (!text) return '';
    return text.charAt(0).toUpperCase() + text.slice(1);
  };

  // Calculate progress value and incentive eligibility
  const calculatedEarning = MemberIncentive?.currentQtrbalance ?? 0;
  const target = MemberIncentive?.latestMonthTarget ?? 0;
  const currentmonth = MemberIncentive?.latestMonthSales ?? 0;
  const achieved = MemberIncentive?.currentQtrRevenue ?? 0;
  const totaltarget = MemberIncentive?.totalTargets ?? 0;
  const progressValue = (achieved / totaltarget) * 100;
  const isNOTEligibleForIncentive = target !== 0 && calculatedEarning < 0;
  const progressColorScheme = isNOTEligibleForIncentive ? 'red' : 'green'




  const handlePay = async () => {
    try {
      const response = await api.get(`/portal/pay_incentive?id=${userId}`);

      if (response?.data?.Data?.incentive?.paidStatus === true) {

        refetch();
      }
    }
    catch (err) {
      console.log(err, "error--------------")
    }

  }


  const handleYearChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedYear(parseInt(e.target.value));
  };



  return (

    <div>
      <Button mb={4} onClick={() => navigate(-1)}>
        Go Back
      </Button>

      <section className={classes.summary}>
        <Flex justifyContent={"space-between"} alignItems={"center"} mt={5}>

          <VStack align="start" w={'100%'} maxW={'600px'}>
            <Heading >{capitalizeFirstLetter(selecteduser?.name)}</Heading>
            <Text fontSize="lg" fontWeight="bold" color="blue.500">{selecteduser?.email}</Text>
            <Card p={3} w={"100%"} maxW={'420px'} mt={4}>
              <Text mb={2} ml={2} style={{ fontSize: "large", fontWeight: "bold" }} >Detail: </Text>
              <Tabs variant='soft-rounded'>
                <TabList>
                  <Tab _selected={{ bg: "#F36E21", color: "white" }}>Outcome</Tab>
                  <Tab _selected={{ bg: "#F36E21", color: "white" }}>Upsell</Tab>
                </TabList>
                <TabPanels>
                  <TabPanel>
                    <Box maxW={'620px'} w={'100%'}>
                      <Text>  Target : <Text as="span" fontWeight="bold">${target?.toFixed(2)}</Text></Text>
                      <Text>   Monthly Achieved : <Text as="span" fontWeight="bold">${currentmonth?.toFixed(2)}</Text></Text>
                      <Text>Quarterly Achieved :  <Text as="span" fontWeight="bold">${achieved?.toFixed(2)}</Text></Text>


                      <Box maxW={'320px'} w={'100%'}>
                        <Progress
                          value={progressValue}
                          borderRadius={'30px'}
                          size='sm'

                          colorScheme={target > 0 ? progressColorScheme : "gray"}

                        />
                      </Box>


                      <Flex mt={2} gap={'8px'}>
                        {target > 0 ? (
                          isNOTEligibleForIncentive ?
                            <>
                              <Text style={{ fontSize: "medium", fontWeight: "bold" }} > Not eligible for an incentive! </Text>
                              <Text color="red.500" style={{ fontSize: "large", fontWeight: "bold" }}>${calculatedEarning?.toFixed(2)}</Text>
                            </>
                            :
                            <>
                              <Text style={{ fontSize: "medium", fontWeight: "bold" }} >Eligible for an incentive! </Text>
                              <Text color="green.500" style={{ fontSize: "large", fontWeight: "bold" }}>Rs. {calculatedEarning?.toFixed(2)}</Text>
                            </>

                        )
                          : (
                            <Text style={{ fontSize: "medium", fontWeight: "bold" }}>
                              Target not assigned yet.
                            </Text>
                          )}
                      </Flex>


                      {(MemberIncentive && MemberIncentive?.quarterComplete === true && selecteduser?.incentive?.incentiveAmount > 0) &&
                        <Button bg="#F36E21" color="white" mt={4} onClick={handlePay}>Pay Incentive</Button>}
                    </Box>
                  </TabPanel>
                  <TabPanel>
                    <Box>
                      {/* <Text mb={2}  style={{ fontSize: "large", fontWeight: "bold" ,color:"#3182ce"}} >Upsell: </Text> */}
                      <Text>{renderSalesData()}</Text>

                    </Box>
                  </TabPanel>
                </TabPanels>
              </Tabs>

            </Card>

          </VStack>

          <Flex direction="column" alignItems={'flex-end'} gap="20px">
            <Flex gap={"10px"}>

              <Select
                placeholder="Over All"
                value={selectedPortalId ?? ''}
                onChange={(e) => handlePortalClick(e.target.value)}

                bg={selectedPortalId ? "#F36E21" : "#dfd6d68a"}
                color="black"
              >
                {portaldata &&
                  portaldata.map((portal) => (
                    <option
                      key={portal._id}
                      value={portal._id}
                    >
                      {portal.portalName}
                    </option>
                  ))}

              </Select>
            </Flex>
            <Flex gap={"10px"}>

              <Button
                bg={count === 4 ? "#F36E21" : "#dfd6d68a"}
                color={count !== 4 ? "black" : "white"}
                onClick={() => setCount(4)}
              >
                Daily
              </Button>

              <Button
                bg={count === 3 ? "#F36E21" : "#dfd6d68a"}
                color={count !== 3 ? "black" : "white"}
                onClick={() => setCount(3)}
              >
                Weekly
              </Button>
              <Button
                bg={count === 0 ? "#F36E21" : "#dfd6d68a"}
                color={count !== 0 ? "black" : "white"}

                onClick={() => setCount(0)}
              >
                Monthly
              </Button>
              <Button
                bg={count === 1 ? "#F36E21" : "#dfd6d68a"}
                color={count !== 1 ? "black" : "white"}

                onClick={() => setCount(1)}
              >
                Yearly
              </Button>
              <Button
                bg={count === 2 ? "#F36E21" : "#dfd6d68a"}
                color={count !== 2 ? "black" : "white"}

                onClick={() => setCount(2)}
              >
                Total
              </Button>
            </Flex>
            <Flex gap={2} alignItems="center">
              <Text>From</Text>
              <Input
                type="date"
                w={'fit-content'}
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
              <Text>To</Text>
              <Input
                type="date"
                w={'fit-content'}
                value={endDate}
                min={startDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
              <Button
                bg={count === 5 ? "#F36E21" : "#dfd6d68a"}
                color={count !== 5 ? "black" : "white"}
                onClick={handleSearch}>
                Search</Button>
            </Flex>
          </Flex>
        </Flex>
        {/* _______________________UserData___________________ */}
        <div className={classes.summary__box}>

          {data && (
            <>
              <UserDetailBox
                key={Proposals.text}
                item={Proposals}
              />
              <UserDetailBox key={Connects.text} item={Connects} />
              <UserDetailBox key={Sales.text} item={Sales} />
              <UserDetailBox
                key={ConnectsCost.text}
                item={ConnectsCost}
              />
              {/* <UserDetailBox key={Amount.text} item={Amount} /> */}
            </>
          )}
        </div>
      </section>
      <Flex direction="column" alignItems="center" bg="white" p={8} rounded="md" boxShadow="md" mt={6}>
        <Box width="100%" mt={4}>
          <Text fontSize="lg" fontWeight="bold" mb={4}>Allocated Profiles</Text>

          {selecteduser?.profilesAllocated?.length > 0 ? (
            loadings ? (
              <LoadingSpinner />
            ) : (
              <CustomTable
                key={Date.now()}
                headData={allocateprofile}
                bodyData={selecteduser?.profilesAllocated}
                limit={5}
                userdata={true}
                portalIdToName={portalIdToName}
              />
            )
          ) : (
            <Text textAlign="center" fontSize="medium" fontWeight="bold" >Profile not assigned yet.</Text>
          )}

        </Box>
        <Box width="100%" mt={4}>
          <Text fontSize="lg" fontWeight="bold" mb={4}>Hired Proposals</Text>
          <Input
            type="text"
            w={'fit-content'}
            placeholder="Search by link/profile or username"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />

          {filteredData.length > 0 ?
            <CustomTable key={Date.now()} headData={userhiredPorposal} bodyData={filteredData} limit={10} userdata={true} />
            :
            <Text textAlign="center" fontSize="medium" fontWeight="bold" > No hired proposals available at this time.</Text>
          }
        </Box>

        <Box width="100%" mt={4}>
          <Text fontSize="lg" fontWeight="bold" mb={4}>Allocated Target</Text>

          {selecteduser?.target?.length > 0 ? (
            loadings ? (
              <LoadingSpinner />
            ) : (
              <CustomTable
                key={Date.now()}
                headData={allocatetarget}
                bodyData={selecteduser?.target.map((target: any) => ({
                  ...target,
                  userId: selecteduser._id,
                }))}
                limit={5}
                userdata={true}
                portalIdToName={portalIdToName}
                setSelected={setSelected}
              />
            )
          ) : (
            <Text textAlign="center" fontSize="medium" fontWeight="bold" >Target not assigned yet.</Text>
          )}

        </Box>

        <Box width="100%" mt={4}>
          <Flex justifyContent="space-between" alignItems="center" mb={4}>
            <Text fontSize="lg" fontWeight="bold">Monthly Revenue Detail</Text>


            <Select
              width="150px"
              onChange={handleYearChange}
              value={selectedYear}
            >
              {years.map(year => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </Select>
          </Flex>

          {monthdata?.length > 0 ? (
            loadings ? (
              <LoadingSpinner />
            ) : (
              <CustomTable
                key={Date.now()}
                headData={monthlyRevenue}
                bodyData={monthdata}
                limit={12}
              />
            )
          ) : (
            <Text textAlign="center" fontSize="medium" fontWeight="bold">
              Revenue detail not present.
            </Text>
          )}
        </Box>
      </Flex>


    </div>

  );
};

export default UserDetail;

